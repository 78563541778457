import { normalizeAvatar } from 'tools/Files/normalize'
import { intcmp, strcmp } from 'utils/string'
import { fromNowShort } from 'utils/time'

export function defaultUser() {
  return {
    id: '',
    settings: {},
    handle: '',
    name: '',
    age: 0,
    createdAgo: '',
    verified: false,
    avatar: { default: true },
    displayEmail: '',
    dataTypes: {
      tags: { value: {} },
      profile: { value: {} },
      address: { value: {} },
      toggles: { value: {} },
      roles: { value: {} },
      skills: { value: {} }
    },
    emails: [],
    data: [],
    ...normalizeUserParam('data', []),
    ...normalizeUserParam('tags', []),
    subscriptions: [],
    unreadThreads: 0,
    authStatus: 'unknown',
    access_token: undefined,
    access_token_expires: 0,
    validation_token: undefined,
    can: (x) => false,
    is: (x) => false,
    allows: (x, y) => false,
    isIdentified: false,
    isAuthed: false
  }
}

// revise/replace w/newer tags2 stuff
export function normalizeUserTags(value) {
  const tagsD = (value || []).reduce((acc, tag) => {
    const current = acc[tag.type] || []
    // collapse usermap and tag
    const meta = { ...(tag.meta || {}), ...(tag.tag.meta || {}) }
    acc[tag.type] = current.concat(
      Object.freeze({
        ...tag,
        ...tag.tag,
        meta,
        userTagId: tag.id
      })
    )

    return acc
  }, {})

  // sort things
  return Object.keys(tagsD).reduce((acc, k) => {
    acc[k] = tagsD[k].toSorted(
      (a, b) => intcmp(a.order, b.order) || strcmp(a.label, b.label)
    )
    return acc
  }, {})
}

// input 'key' and value, always return new dictionary values that
// are to be merged into parent dictionary
export function normalizeUserParam(key, value) {
  switch (key) {
    case 'data':
      // TODO:
      // * switch address to 'location' or 'locale'
      // * switch profile to ... something else
      const data = {
        dataTypes: {
          tags: { value: {} },
          profile: { value: {} },
          address: { value: {} },
          toggles: { value: {} },
          ...(value || []).reduce((a, d) => {
            a[d.type] = d
            return a
          }, {})
        }
      }
      data.toggles = data.dataTypes.toggles.value
      return data

    case 'tags':
      // return { tagsD: normalizeTags2(value) }
      return {
        tags: value,
        tagsD: normalizeUserTags(value)
      }
    //   tagsD: (value || []).reduce((acc, tag) => {
    //     const current = acc[tag.type] || []
    //     console.log("WTF23")
    //     // collapse usermap and tag
    //     console.log({tag})
    //     acc[tag.type] = current.concat({
    //       ...tag,
    //       ...tag.tag,
    //       userTagId: tag.id
    //     })
    //     return acc
    //   }, {})
    // }

    case 'emails':
      if (value && value.length > 0) {
        const vemails = value.filter((e) => e.verified)
        let verified = false
        let displayEmail = ''
        if (vemails.length > 0) {
          verified = true
          displayEmail = vemails[0].address
        } else {
          displayEmail = value[0].address
        }
        return { verified, displayEmail }
      }
      return {}

    default:
      return { [key]: value }
  }
}

// export function normalizeUserAvatar(value) {
//   if (value.path) {
//     return { ...value, url: config.pub + value.path }
//   }
//   return { default: true }
// }

// this is intentionally non-immutable, which is only done for performance
// on high level things like a list of a lot of users.  Don't put a lot into
// this -- most things should go into normalizeUser and create a new instance
export function normalizePublicUser(user, real) {
  if (!user || user._normal) return user
  user = { ...user }

  user.allows = (actor, action) => {
    return actor.id === user.id || actor.is('superadmin')
  }

  user.age = (Date.now() - new Date(user.insertedAt).getTime()) / 86400000
  user.createdAgo = fromNowShort(user.insertedAt)
  user.lastSeenAgo = fromNowShort(user.lastSeen)

  user.avatar = normalizeAvatar(user.avatar.path, user.id)

  // not a fan of this (Object.assign()) pattern -BJG
  Object.assign(user, normalizeUserParam('tags', user.tags))

  Object.assign(user, normalizeUserParam('data', user.data))

  user._last = Date.now()
  user._normal = real || false

  return user
}

// this always returns a new object
export function normalizeUser(indata, real) {
  let roles = new Set()
  let actions = new Set()
  if (indata.access) {
    if (indata.access.roles) roles = new Set(indata.access.roles)
    if (indata.access.actions) actions = new Set(indata.access.actions)
  }

  const authStatus = (indata ? indata.authStatus : undefined) || 'unknown'

  const user = {
    ...defaultUser(),
    ...indata,
    roles,
    actions,
    authStatus,
    ...normalizeUserParam('emails', indata.emails),
    can: (action) => actions.has(action),
    is: (role) => roles.has(role),
    isIdentified: authStatus !== 'unknown',
    isAuthed: ['authed', 'multi-authed'].includes(authStatus),
    _normal: false
  }

  let u = normalizePublicUser(user, real)
  return u
}
