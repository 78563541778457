import { Cookies } from 'react-cookie'

import { reducer as notifyReducer } from 'tools/Notify/reducer'
import { defaultState as defaultNotify } from 'tools/Notify/reducer'
// import { R_PAGE, defaultPage } from 'tools/Navigator'
import apollo from 'utils/apollo'
import { authRequest, signonDropStates, signonInitialState } from 'utils/signon'
import { defaultUser, normalizeUser } from 'utils/user'

import { signonReducer } from 'reducer/signon/reducer'

export const cookies = new Cookies()
export const COOKIE_NAME = 'e'
// const starting = cookies.get(COOKIE_NAME) || {}

////////////////////////////////////////////////////////////////////////////////
export function generateBaseGlobalState(seed) {
  return Object.freeze({
    notify: defaultNotify,
    ...seed,
    apolloInit: false,
    apollo: apollo(() => {}),
    nav: undefined,
    title: undefined,
    theme: 'dark',
    // pageMain: Object.freeze({ bar: [], menu: [] }),
    // pageSection: defaultPageSub,
    // pageSub: defaultPageSub,
    // page: Object.freeze({
    //   theme: 'theme-dark',
    //   section: undefined,
    //   // top right nav
    //   mainNav:
    //   // nav-bar section nav
    //   sectionNav: undefined,
    //   // secondary nav
    //   subTitle: undefined,
    //   subNav: undefined,
    //   // innerNav: undefined,
    //   // navOverride: undefined
    //   // hideChrome: false,
    //   // title: '',
    // }),
    // sectionRouter bodgering
    user: defaultUser(),
    signon: signonInitialState(),
    // used by docs to set viewing preferences—move to local storage
    prefs: { show: {} }
    // // OLD THINGS
    // prefs: {
    //   nav: { gaz: 1 },
    //   show: {},
    //   filters: {},
    //   orgList: {
    //     name: ''
    //   },
    //   orgList_t: 0,
    //   projectList: {
    //     goal: 'any',
    //     needs: [],
    //     files: [],
    //     activity: 'quarter',
    //     name: ''
    //   },
    //   projectList_t: 0,
    //   connect: {
    //     name: '',
    //     topics: []
    //   },
    //   connect_t: 0,
    //   skip: true,
    //   ...starting
    // },
    // query: {},
    // saving: false,
    // savingKeys: {},
    // history: [window.location.pathname],
    // interfaceConfig: {}
  })
}

// export const defaultBaseGlobalState = generateBaseGlobalState({})

export function resetBaseGlobalState(oldState, newSeed) {
  oldState.apollo.cache.reset()
  signonDropStates()
  authRequest('signout', {})

  // keep notify messages across state transitions
  return generateBaseGlobalState({
    notify: oldState.notify,
    nav: oldState.nav,
    ...newSeed
  })
}

export const BASE_R_GLOBAL = {
  APOLLO_RESET: 1001,
  NOTIFY: 2000,
  USER_SET: 4001,
  USER_MERGE: 4002,
  PAGE_NAV_STATE: 6000,
  PAGE_NAV_SPEC: 6001,
  PAGE_THEME: 6003,
  PAGE_TITLE: 6004,
  PAGE_MENU: 6005,
  PREFS_SET: 7001,
  PREFS_READY: 7002,
  PREFS_SHOW_COLS: 7004,
  PREFS_FILTER_COLS: 7005
}

export function baseGlobalReducer(state, { type, ...action }) {
  if (type === undefined) {
    console.log({ action })
    throw new Error(`baseGlobalReducer - undefined dispatch type`)
  }
  switch (type) {
    case BASE_R_GLOBAL.APOLLO_RESET:
      return { ...state, apolloInit: true, apollo: apollo(action.dispatch) }

    case BASE_R_GLOBAL.NOTIFY:
      return { ...state, notify: notifyReducer(state.notify, action.value) }

    //////////////////////////////
    case BASE_R_GLOBAL.USER_SET: {
      const user = normalizeUser(action.value, true)
      return { ...state, user }
    }

    case BASE_R_GLOBAL.USER_MERGE:
      return { ...state, user: { ...state.user, ...action.value } }

    //////////////////////////////
    // this is a reset
    case BASE_R_GLOBAL.PAGE_NAV_STATE:
      return {
        ...state,
        nav: Object.freeze({ ...state.nav, ...action.value.nav })
      }

    case BASE_R_GLOBAL.PAGE_NAV_SPEC: {
      const { spec } = action
      return {
        ...state,
        nav: Object.freeze({ itemId: undefined, current: undefined, spec })
      }
    }

    case BASE_R_GLOBAL.PAGE_THEME:
      return Object.freeze({ ...state, pageTheme: action.value })

    case BASE_R_GLOBAL.PAGE_TITLE:
      return Object.freeze({ ...state, title: action.title })

    // todo: revise the state updates of this using Navigator, to separate
    // specs from runtime states
    case BASE_R_GLOBAL.PAGE_MENU: {
      const { userMenu, siteMenu } = action
      return Object.freeze({ ...state, menus: { userMenu, siteMenu } })
    }

    // case R_GLOBAL.TAGS_SET:
    //   return {
    //     ...state,
    //     user: {
    //       ...state.user,
    //       profile: action.value,
    //       ...normalizeUserParam('profile', action.value)
    //     }
    //   }
    //
    case BASE_R_GLOBAL.PREFS_SET: {
      const prefs = {
        ...state.prefs,
        [action.key]: { ...state.prefs[action.key], ...action.value }
      }
      // cookies.set(COOKIE_NAME, prefs, { path: '/' })
      return { ...state, prefs: { ...prefs, skip: true } }
    }

    case BASE_R_GLOBAL.PREFS_READY:
      return { ...state, prefs: { ...state.prefs, skip: false } }

    case BASE_R_GLOBAL.PREFS_SHOW_COLS: {
      let prefs = state.prefs || {}
      const show = prefs.show || {}
      const { key, ...rest } = action
      show[key] = { ...rest }
      prefs = { ...prefs, show: { ...show } }
      cookies.set(COOKIE_NAME, prefs, { path: '/' })
      return { ...state, prefs: { ...prefs } }
    }

    case BASE_R_GLOBAL.PREFS_FILTER_COLS: {
      let filters = state.prefs.filters || {}
      const { key, reset, merge, set } = action
      if (reset) {
        filters = {}
      } else if (merge) {
        filters[key] = { ...(filters[key] || {}), ...merge }
        console.log('MERGE', filters, merge)
      } else if (set) {
        filters[key] = set
      }
      const prefs = { ...state.prefs, filters: { ...filters } }
      cookies.set(COOKIE_NAME, prefs, { path: '/' })
      return { ...state, prefs: { ...prefs } }
    }

    // case R_GLOBAL.NAV_ACTIVITY: {
    //   let { menu, bar, value } = action
    //   if (menu) {
    //     menu = navActivity(state.nav.menu, menu, value)
    //     return { ...state, nav: { ...state.nav, menu } }
    //   }
    //   bar = navActivity(state.nav.bar, bar, value)
    //   return { ...state, nav: { ...state.nav, bar } }
    // }
    //
    // case R_GLOBAL.MERGE_EDITABLE:
    //   return { ...state, ...action }

    default:
      return signonReducer(state, type, action)
  }
}

// function navActivity(list, key, value) {
//   return list.map((item) => {
//     if (item.label === key) {
//       return { ...item, activity: value }
//     }
//     return item
//   })
// }
