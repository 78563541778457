import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'

import { useWait } from 'tools/Loading'
import notifier from 'tools/Notify'
import { Label } from 'tools/Uniform'
import { stopEvent } from 'utils/event'
import { noop } from 'utils/function'

export const CHG = {
  OK: 0,
  DIRTY: 1,
  FAILED: 2,
  SAVED: 3,
  SAVING: 4
}

function PlaceholderInput(props) {
  const wait = useWait(1000)

  if (!wait) return <span />
  return (
    <InnerInput
      {...props}
      onSave={noop}
      onChange={noop}
      placeholder={'loading…'}
    />
  )
}

export function Input({ loading = false, ...props }) {
  if (props.value == null || loading) return <PlaceholderInput {...props} />
  return <InnerInput {...props} />
}

function statusToClass(status) {
  switch (status) {
    case CHG.DIRTY:
      return 'dirty'
    case CHG.FAILED:
      return 'failed'
    default:
      return ''
  }
}

function calculateHeight(elem, multiline) {
  if (multiline) {
    const MIN_HEIGHT = 30
    const origHeight = elem.style.height
    // this is a little game to trick the scrollHeight to be as tight as possible
    elem.style.height = 'auto'
    const newHeight = Math.max(MIN_HEIGHT, elem.scrollHeight)
    elem.style.height = origHeight
    return newHeight
  } else {
    return 'auto'
  }
}

function InnerInput({
  onError = noop,
  value,
  onSave = (props, value, good, bad) => {},
  onChange: changer,
  onCancel,
  style = {},
  label,
  labelClass,
  info,
  markdown = false,
  help,
  helpClass = 'f3 mb3 gray',
  wrapClass = '',
  className = '',
  multiline = false,
  placeholder = '',
  makeVars,
  minRows = 1,
  defaultIcon,
  saveButton,
  children,
  ...args
}) {
  const [, notifyD] = useContext(notifier.Context)
  const ref = useRef(null)
  const [state, setState] = useState({
    value: value,
    prev: value,
    style: style,
    status: CHG.OK,
    cancel: false,
    error: undefined
  })

  useEffect(() => {
    const textarea = ref?.current
    if (textarea && multiline) {
      setState((state) => ({
        ...state,
        style: { ...state.style, height: calculateHeight(textarea, multiline) }
      }))
    }
  }, [ref, multiline])

  const save = useCallback(
    (e) => {
      if (state.value === state.prev) {
        setState({ ...state, status: CHG.OK })
        return
      }
      const textarea = e.currentTarget
      return new Promise((resolve, reject) => {
        return onSave(
          { ...args, makeVars, dirty: state.status },
          e.target.value,
          resolve,
          reject
        )
      })
        .then((status = CHG.SAVED) => {
          setState({
            ...state,
            status,
            prev: state.value,
            error: undefined,
            style: {
              ...state.style,
              height: calculateHeight(textarea, multiline)
            }
          })
          if (status === CHG.SAVED) {
            notifier.saved(notifyD, true)
            setTimeout(() => {
              setState((state) => ({ ...state, status: CHG.OK }))
            }, 1000)
          }
        })
        .catch((errmsg) => {
          setState({ ...state, error: errmsg, status: CHG.FAILED })
          if (typeof errmsg === 'string') {
            notifier.error(notifyD, errmsg)
          }
          onError(errmsg)
        })
    },
    [onSave, args, state, setState, onError, notifyD, makeVars, multiline]
  )

  const onChange = useCallback(
    (e) => {
      const textarea = e.target
      const value = textarea.value
      setState((state) => ({
        ...state,
        value,
        style: { ...state.style, height: calculateHeight(textarea, multiline) },
        cancel: false,
        status: CHG.DIRTY
      }))
      notifier.editing(notifyD)
      changer(value, args)
    },
    [setState, changer, args, notifyD, multiline]
  )

  const onFocus = useCallback(
    (e) => {
      const textarea = e.target
      setState((state) => ({
        ...state,
        cancel: false,
        style: { ...state.style, height: calculateHeight(textarea, multiline) }
      }))
    },
    [multiline]
  )

  const onKeyDown = useCallback(
    (ev) => {
      if (!multiline && ev.keyCode === 13 && !ev.shiftKey) {
        stopEvent(ev)
        return save(ev)
      }
    },
    [multiline, save]
  )

  const status = statusToClass(state.status)
  return (
    <>
      {label && (
        <Label
          markdown={markdown}
          children={label}
          info={info}
          className={labelClass}
          maxLength={args.maxLength}
          value={state.value}
          dirty={state.status}
          help={help}
          helpClass={helpClass}
        />
      )}
      <div className="flex-items w-100">
        <div className={wrapClass + ' input-inline relative w-100'}>
          <textarea
            className={className + ' ' + status}
            value={value}
            ref={ref}
            rows={minRows}
            style={state.style}
            onKeyDown={onKeyDown}
            onBlur={saveButton ? () => {} : save}
            onChange={onChange}
            onFocus={onFocus}
            placeholder={placeholder}
            {...args}
          />
          <ShowIcon
            status={state.status}
            setOk={() => setState({ ...state, status: CHG.OK })}
            defaultIcon={defaultIcon}
          />
          {saveButton && state.status === CHG.DIRTY && !state.cancel && (
            <div className="flex absolute right-0 z-9999 mt1 gh2">
              <SaveButton
                save={() => {
                  setState((s) => ({ ...s, cancel: true }))
                  onCancel && onCancel(false)
                }}
                label={<i className="fas fa-times" />}
                xref={ref}
              />
              <SaveButton
                save={save}
                label={<i className="fas fa-check" />}
                className="neutral"
                xref={ref}
              />
            </div>
          )}
        </div>
        {children}
      </div>
    </>
  )
}

function ShowIcon({ setOk, status, defaultIcon }) {
  switch (status) {
    case CHG.FAILED:
      return (
        <div onClick={setOk} className="mark error center-y pa1 br2">
          <i className="red fas fa-times" />
        </div>
      )
    case CHG.SAVED:
      return (
        <div onClick={setOk} className="mark ok center-y pa1 br2">
          <i className="green fas fa-check" />
        </div>
      )
    default:
      return defaultIcon ? (
        <div className="mark ok center-y pa1 br2">{defaultIcon}</div>
      ) : null
  }
}

function SaveButton({ save, label, xref, className = 'neutral' }) {
  return (
    <button
      onClick={(e) => {
        e.currentTarget = xref.current
        e.target = xref.current
        save(e)
      }}
      className={className}
    >
      {label}
    </button>
  )
}

export default Input
