import React, { useContext, useState } from 'react'

import Input from 'tools/Input2'

export function AddButton({
  onClick,
  children = undefined,
  className = 'f1 w-100'
}) {
  return (
    <button className={`${className} clear large ph2 pv1`} onClick={onClick}>
      <i className="fas fa-plus mr2" />
      {children}
    </button>
  )
}

export function Divider({ children = <>&nbsp;</> }) {
  return <div className="flex-center f1 gray mv3 bg-accent w-100">{children}</div>
}

// like AddButton but wrapping the initial field and create
export const DEFAULT_EDIT = { open: false, error: undefined }

// split out valState so others can give their own for extra behavior
export function CreateButton(args) {
  const valState = useState(DEFAULT_EDIT)
  return <CreateButtonValState valState={valState} {...args} />
}

export function CreateButtonValState({
  onSave,
  valState,
  placeholder,
  children,
  label = undefined,
  className = undefined,
  ...args
}) {
  const [edit, setEdit] = valState
  const [input, setInput] = useState('')
  const submit = () => {
    onSave(
      args,
      input,
      () => {
        setEdit(DEFAULT_EDIT)
      },
      (reason) => {
        setEdit({ ...DEFAULT_EDIT, error: reason })
      }
    )
  }

  if (edit.open) {
    return (
      <>
        <div className={`flex-center ${className}`}>
          {label ? <label className="mr2">{label}:</label> : null}
          <input
            placeholder={placeholder}
            onChange={(ev) => setInput(ev.target.value)}
            onKeyDown={(ev) => {
              // @ts-ignore
              if (ev.key === 'Enter') {
                // @ts-ignore
                submit()
              }
            }}
            className="w-90 mr3"
            autoFocus={true}
          />
          <button
            className="plain large ml-auto"
            onClick={() => setEdit({ ...DEFAULT_EDIT, open: false })}
          >
            Cancel
          </button>
          <button className="large ml3" onClick={submit}>
            Create
          </button>
        </div>
        {edit.error ? <div className="red">{edit.error}</div> : null}
      </>
    )
  }
  return (
    <AddButton
      className={className}
      onClick={() => setEdit({ ...DEFAULT_EDIT, open: true })}
    >
      {children}
    </AddButton>
  )
}

export function AngleIcon({ open, className = '' }) {
  return <i className={`fas fa-angle-${open ? 'down' : 'right'} ${className}`} />
}

export function PivotIcon({ open, className = '' }) {
  return <i className={`fas fa-${open ? 'minus' : 'plus'}-square ${className}`} />
}

export function CheckIcon({ checked, className = '' }) {
  return (
    <i className={`${checked ? 'fas fa-check' : 'far fa'}-square ${className}`} />
  )
}

export function TabFrame({ children, className = 'pa4' }) {
  return <div className={`vp3-l base-frame mb5 ${className}`}>{children}</div>
}

export function Label({
  maxLength,
  value,
  dirty,
  children,
  className = 'mb3 mt4',
  markdown = false,
  info,
  help,
  helpClass
}) {
  if (markdown && !info) {
    info = 'supports markdown formatting'
  }
  return (
    <label
      className={`accent flex items-center flex-wrap flex-wrap-none-s ga2 ${className}`}
    >
      {children}
      {info && <div className="fw1 fw1 i">({info})</div>}
      {maxLength && <Showlength value={value} max={maxLength} dirty={dirty} />}
    </label>
  )
}

function Showlength({ value, max, dirty }) {
  if (!max || !dirty) {
    return null
  }
  return (
    <div className={`fw1 f2 i ${value?.length === max ? '' : 'minimize'}`}>
      ({value.length} of {max})
    </div>
  )
}

/*
export function Label({ children, className = 'mt4 mb3', info }) {
  return (
    <label className={`accent ${className}`}>
      {children}
      {info && (<span className="fw1 i f2">({info})</span>)}
    </label>
  )
} */

// MaxViewPage outside of a tabbed frame
export function MaxViewPage({
  className = 'mb8 mt4 mt5-l',
  children,
  style = undefined
}) {
  return (
    <div className={`vp3-l relative mh3 mh0-l ${className}`} style={style}>
      {children}
    </div>
  )
}

export function Heading({ className = 'mv4', children }) {
  return <h1 className={`f5 accent fw6 ${className}`}>{children}</h1>
}

const ELLIPSIS_STYLE = {
  lineBreak: 'anywhere',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden'
}

export function Ellipsis({ children, text, className = '' }) {
  return (
    <span className="flex items-center">
      <span title={text || children} className={className} style={ELLIPSIS_STYLE}>
        {text || children}
      </span>
    </span>
  )
}

export function Pill({ children, className = '' }) {
  return <span className={`br-pill ba ph2 ${className}`}>{children}</span>
}

export function Create({ Context, singular, token = 'name' }) {
  const [state] = useContext(Context)
  const [name, setName] = useState('')

  return (
    <MaxViewPage>
      <Input
        heading="heading mb2"
        value={name}
        token={token}
        onChange={(v) => setName(v)}
        onSave={state.onSave}
        saveButton={true}
        placeholder={singular + ' Name'}
        label={`Create ${singular}`}
        className="b"
      />
    </MaxViewPage>
  )
}
