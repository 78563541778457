const authorVars = 'author { id handle avatar name }'

const topicBaseValues = `
id
shortId
${authorVars}
subject
meta
visible
locked
insertedAt
updatedAt
`

export const topicListValues = `
${topicBaseValues}
summary
`

export const topicLoadValues = `
${topicBaseValues}
body
`

export const messageValues = `
id
${authorVars}
parentId
body
meta
visible
locked
insertedAt
updatedAt
`
