import { formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns'

import locale from 'date-fns/locale/en-US/index.js'

export const YEAR = new Date().getFullYear()

// export function formatDateTime(time) {
//   if (!time) {
//     return null
//   }
//   const m = moment(time)
//   if (m.year() === YEAR) {
//     return m.format('ddd, MMM Do · h:mma')
//   } else {
//     return m.format('ddd, MMM Do YYYY · h:mma')
//   }
// }

export function formatDate(date, args = {}) {
  return getDate(date).toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    ...args
  })
}

function getDate(date) {
  if (date instanceof Date) {
    return date
  } else {
    return new Date(date)
  }
}

export function fromNow(time) {
  if (!time) return ''
  return formatDistanceToNow(getDate(time))
}

const MONTHS_DIV = 86400 * 1000 * 30.5
export function fromNowWithDate(time) {
  if (!time) return ''
  time = getDate(time)
  const ago = formatDistanceToNow(time)
  const diff = (new Date() - time) / MONTHS_DIV
  if (diff > 3) {
    return { ago, date: formatDate(time) }
  } else {
    return { ago }
  }
}

const formatDistanceAbbrev = {
  lessThanXSeconds: '{{x}} s',
  xSeconds: '{{x}} s',
  halfAMinute: '30s',
  lessThanXMinutes: '{{x}} min',
  xMinutes: '{{x}} min',
  aboutXHours: '{{x}} hr',
  xHours: '{{x}} hr',
  xDays: '{{x}} d',
  aboutXWeeks: '{{x}} w',
  xWeeks: '{{x}} w',
  aboutXMonths: '{{x}} mo',
  xMonths: '{{x}} mo',
  aboutXYears: '{{x}} y',
  xYears: '{{x}} y',
  overXYears: '{{x}} y',
  almostXYears: '{{x}} y'
}

function formatDistance(token, count) {
  return formatDistanceAbbrev[token].replace('{{x}}', count)
}

export function fromNowShort(t) {
  if (!t) return ''
  return formatDistanceToNowStrict(getDate(t), {
    addSuffix: true,
    locale: {
      ...locale,
      formatDistance
    }
  })
}

const msPerSecond = 1000
const msPerMinute = msPerSecond * 60
const msPerHour = msPerMinute * 60
const msPerDay = msPerHour * 24
const msPerMonth = msPerDay * 30
const msPerYear = msPerDay * 365

function _timePart(ms) {
  if (ms < msPerMinute) {
    return [Math.floor(ms / msPerSecond), 'sec', msPerSecond]
  } else if (ms < msPerHour) {
    return [Math.floor(ms / msPerMinute), 'min', msPerMinute]
  } else if (ms < msPerDay) {
    return [Math.floor(ms / msPerHour), 'hr', msPerHour]
  } else if (ms < msPerMonth) {
    return [Math.floor(ms / msPerDay), 'day', msPerDay]
  } else if (ms < msPerYear) {
    return [Math.floor(ms / msPerMonth), 'mo', msPerMonth]
  }
  return [Math.floor(ms / msPerYear), 'year', msPerYear]
}

function timePart(ms) {
  let [num, label, chunk] = _timePart(ms)
  if (num > 1) {
    return [num, label + 's', chunk]
  }
  return [num, label, chunk]
}

// all the parts
export function timeDifferenceParts(current, previous) {
  let elapsed = current - previous
  let result = []
  while (elapsed > msPerSecond) {
    const [num, label, chunk] = timePart(elapsed)
    if (num <= 0) {
      return result
    }
    result.push(`${num} ${label}`)
    // @ts-ignore
    elapsed -= num * chunk
  }
  return result
}

// just the biggest component
export function timeDifference(current, previous) {
  const elapsed = current - previous

  if (elapsed < msPerMinute / 3) {
    return 'just now'
  }
  if (elapsed < msPerMinute) {
    return 'less than 1 min ago'
  }

  const [num, label] = timePart(elapsed)

  return `${num} ${label} ago`
}

export function secondsForHuman(seconds) {
  if (seconds > 86400) {
    return `${Math.round(seconds / 86400)}d`
  } else if (seconds > 3600) {
    return `${Math.round(seconds / 3600)}h`
  } else {
    return `${Math.round(seconds / 60)}m`
  }
}

export function timeDiff(date) {
  const now = new Date().getTime()
  return date - now
}

export function timeDifferenceForDate(date) {
  const now = new Date().getTime()
  const updated = new Date(date).getTime()
  return timeDifference(now, updated)
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

// receive whatever they type, make sure it's what we want, returning
// both the server-server format, and the user-format
export function parseInputTime(str) {}

export function enrichPosixTime(obj, key) {
  if (key in obj) {
    return { ...obj, ['_' + key]: +new Date(obj[key]) }
  }
  return obj
}
