import gql from 'graphql-tag'

import { fileVars } from 'tools/Files/graphql'

import { topicListValues } from 'app/libreon/Forums/graphqlVars'

export const projectListValues = `
id
shortId
title
updatedAt
insertedAt
releasedAt
visibility
rating
website
format
status
avatar
features
`

const projectLoadValues = `
  ${projectListValues}
  subtitle
  license
  summary
  myAccess
  tags {
    id
    tag {
      id
      type
      label
      info
      meta
      group { id label }
    }
  }
  members {
    id
    type
    roles
    user {
      id
      handle
      name
      lastSeen
      avatar
    }
  }
  files {
    ${fileVars}
  }
  data(types: ["profile"]) {
    id
    type
    value
  }
  forum {
    id
    meta
  }
`

// journeyActor {
//   id
//   type
//   journeyMaps {
//     id
//     shortId
//     milestoneId
//     updatedAt
//     journey {
//       id
//       name
//       milestones {
//         id
//         name
//         order
//         meta
//       }
//     }
//   }
// }
export const LIST_PROJECTS = gql`
  query listProjects($filter: InputProjectFilter!) {
    projects(filter: $filter) {
      success
      reason
      total
      offset
      matching
      results {
        ${projectListValues}
      }
    }
  }
`

export const LOAD_PROJECT = gql`
  query loadProject($id: String!) {
    projects(filter: {id: $id}) {
      success
      reason
      total
      matching
      result {
        ${projectLoadValues}
      }
    }
  }
`

export const UPSERT_PROJECT = gql`
  mutation upsertProject($project: InputProject) {
    upsertProject(project: $project) {
      success
      reason
      result {
        ${projectLoadValues}
      }
    }
  }
`

export const UPSERT_PROJECT_MEMBER = gql`
  mutation upsertProjectMember($projectId: String!, $member: InputProjectMember!) {
    upsertProjectMember(projectId: $projectId, member: $member) {
      success
      reason
      meta
      result {
        ${projectLoadValues}
      }
    }
  }
`

export const REACTOR_CHALLENGES = gql`
  query reactorChallenges($projectId: String) {
    reactorChallenges(projectId: $projectId) {
      id
      name
      explain
      visible
      context
      links {
        id
        order
        challengeId
      }
      triggers {
        id
      }
      conditions
      actions
      completed
      journal {
        id
        meta
      }
      meta
    }
  }
`

export const LIST_PROJECT_TOPICS = gql`
  query loadProject($id: String!) {
    projects(filter: {id: $id}) {
      success
      reason
      result {
        id
        forum {
          id
          meta
          topics {
            ${topicListValues}
          }
        }
      }
    }
  }
`
